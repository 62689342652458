<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      color="white"
      elevation="1"
      height="80"
    >
      <!-- <base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="52"
        width="100%"
      /> -->

      <base-img
        :src="require('@/assets/logo-fondIndePresse.jpg')"
        contain
        max-width="200"
        width="100%"
        style="box-shadow: 2px 1px 1px #c1c1b7;"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            :exact="item.name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
      items: [
        { name: 'home', text: 'Accueil', to: '/' },
        { name: 'About', text: 'Qui sommes-nous?', to: '/about' },
        { name: 'Pro', text: 'Missions', to: '/mission' },
        { name: 'Pro', text: 'Actualités', to: '/actu' },
        { name: 'HoContacte', text: 'Nous contacter', to: '/contact-us' },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto

    .v-tab
      &::before
        display: none
</style>
