import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VAppBar,{attrs:{"id":"home-app-bar","app":"","color":"white","elevation":"1","height":"80"}},[_c('base-img',{staticStyle:{"box-shadow":"2px 1px 1px #c1c1b7"},attrs:{"src":require('@/assets/logo-fondIndePresse.jpg'),"contain":"","max-width":"200","width":"100%"}}),_c(VSpacer),_c('div',[_c(VTabs,{staticClass:"hidden-sm-and-down",attrs:{"optional":""}},_vm._l((_vm.items),function(item,i){return _c(VTab,{key:i,staticClass:"font-weight-bold",attrs:{"to":item.to,"exact":item.name === 'Home',"ripple":false,"active-class":"text--primary","min-width":"96","text":""}},[_vm._v(" "+_vm._s(item.text)+" ")])}),1)],1),_c(VAppBarNavIcon,{staticClass:"hidden-md-and-up",on:{"click":function($event){_vm.drawer = !_vm.drawer}}})],1),_c('home-drawer',{attrs:{"items":_vm.items},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }